import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { apiGetAgent } from "services/api/ai-agent";

// Define types for context value
interface LettaClientContextValue {
  agent: string | null;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

// Define props for the provider
interface LettaClientProviderProps {
  children: ReactNode;
}

// Create context
const LettaClientContext = createContext<LettaClientContextValue | undefined>(
  undefined
);

// Custom hook to use LettaClient
export const useLettaClient = (): LettaClientContextValue => {
  const context = useContext(LettaClientContext);
  if (!context) {
    throw new Error("useLettaClient must be used within a LettaClientProvider");
  }
  return context;
};

// Provider component
export const LettaClientProvider: React.FC<LettaClientProviderProps> = ({
  children,
}) => {
  const [agent, setAgent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const initializeClient = async () => {
      try {
        const aiAgent = await apiGetAgent();
        setAgent(aiAgent?.agent_id);
      } catch (error) {
        console.error("Error initializing LettaClient:", error);
      }
    };
    initializeClient();
  }, []);

  return (
    <LettaClientContext.Provider value={{ agent, loading, setLoading }}>
      {children}
    </LettaClientContext.Provider>
  );
};
