export const BIZ_DOMAIN = process.env.REACT_APP_BIZ_DOMAIN;
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const IMG_DOMAIN = process.env.REACT_APP_IMG_DOMAIN;
export const RESIZE_IMG_DOMAIN = process.env.REACT_APP_RESIZE_IMG_DOMAIN;
export const FILE_DOMAIN = process.env.REACT_APP_FILE_DOMAIN;
export const TOKEN = process.env.REACT_APP_TOKEN || "";
export const REACT_APP_GG_APP_ID = process.env.REACT_APP_GG_APP_ID || "";
export const REACT_APP_FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA4_MEASUREMENT_ID;
export const SOURCING_DOMAIN = process.env.REACT_APP_SOURCING_DOMAIN || "";
export const TRACE_DOMAIN = process.env.REACT_APP_TRACE_DOMAIN || "";
export const LANDING_DOMAIN = process.env.REACT_APP_LANDING_DOMAIN;
export const BUYER_CENTER_DOMAIN =
  process.env.REACT_APP_BUYER_CENTER_DOMAIN || "";
export const SUPPLIER_CENTER_DOMAIN =
  process.env.REACT_APP_SUPPLIER_CENTER_DOMAIN;
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || "";
export const GG_APP_ID = process.env.REACT_APP_GG_APP_ID || "";

export const AI_AGENT_API_DOMAIN = process.env.REACT_APP_AI_AGENT_API_DOMAIN;
