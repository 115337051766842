import http, { agentHttp } from "services/http";
import { AgentChatFormType } from "types/ai-agent";

const ENDPOINT = "/api/method/freshdi.api.web.v3.agent";
const AGENT_ENDPOINT = "/v1/agents";

export const apiGetAgent = () =>
  http.get(`${ENDPOINT}.get_agent`).then((res) => res?.data?.message?.data);

export const apiSendAgentMessage = (agent: string, data?: AgentChatFormType) =>
  agentHttp
    .post(`${AGENT_ENDPOINT}/${agent}/messages/stream`, data)
    .then((res) => res?.data);

export const apiGetMessages = (agent: string) =>
  agentHttp
    .get(`${AGENT_ENDPOINT}/${agent}/messages`)
    .then((res) => res?.data);
