import { Stack, styled } from "@mui/material";
import {
  AcceptButton,
  BaseButton,
  Link,
  FreshdiLogo,
  Row,
  SelectLanguage,
} from "Components";
import { Container } from "Components/Container";
import { PATHS } from "constants/routes";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import useNavigate from "hooks/useNavigate";
import React from "react";
import { useIntl } from "react-intl";

type Props = {};

const Header = (props: Props) => {
  const t = useIntl();
  const { search } = useNavigate();
  const { isMobile } = useMediaBreakpoints();

  return (
    <StyledHeader>
      <Container className="content-box">
        <FreshdiLogo />
        <Row>
          <SelectLanguage />
          {!isMobile && (
            <>
              <Link to={{ pathname: PATHS.login, search }}>
                <BaseButton className="login-btn">
                  {t.formatMessage({ id: "login" })}
                </BaseButton>
              </Link>
              <Link to={{ pathname: PATHS.register, search }}>
                <AcceptButton className="signup-btn">
                  {t.formatMessage({ id: "sign_up" })}
                </AcceptButton>
              </Link>
            </>
          )}
        </Row>
      </Container>
    </StyledHeader>
  );
};

const StyledHeader = styled(Stack)(({ theme }) => ({
  position: "sticky",
  top: 0,
  padding: "16px 0",
  backgroundColor: "#fff",
  zIndex: 10,
  borderBottom: "1px solid #66666640",
  ".content-box": {
    justifyContent: "space-between",
    display: "flex",
    height: 40,
    gap: 16,
  },
  "& a .logo": {
    height: 38,
  },
  ".login-btn": {
    borderColor: "var(--green-500)",
  },
  ".signup-btn, .login-btn": {
    minWidth: 98,
    height: 40,
  },
  [theme.breakpoints.down("md")]: {
    padding: "8px 0",
    "& a .logo": {
      height: 32,
    },
  },
}));

export default Header;
