import {
  ADVANCE_CROWN_ICON,
  APP_ICONS,
  PRO_CROWN_ICON,
  STARTED_CROWN_ICON,
} from "Assets/icons";
import { PricingPlanType } from "types/pricing";

const LISTING_SORTERS = [
  { label: "Liên quan nhất", value: "relatest" },
  { label: "Theo giá tiền (Từ cao đến thấp)", value: "price down" },
  { label: "Theo giá tiền (Từ thấp đến cao)", value: "price up" },
  { label: "Mới nhất", value: "newest" },
];

const COUNTRY_FLAG = (code: string) => `https://flagcdn.com/w20/${code}.png`;

export const COUNTRY_CODES = {
  en: "en",
  vi: "vi",
  zh: "zh",
};

const COUNTRIES = [
  {
    value: COUNTRY_CODES.en,
    label: "English",
    matchCode: "gb",
    src: COUNTRY_FLAG("gb"),
  },
  {
    value: COUNTRY_CODES.vi,
    label: "Việt Nam",
    matchCode: "vn",
    src: COUNTRY_FLAG("vn"),
  },
  {
    value: COUNTRY_CODES.zh,
    label: "中国人",
    matchCode: "zh",
    src: COUNTRY_FLAG("cn"),
  },
];

const GENDERS = [
  { value: "male", label: "Common.male" },
  { value: "female", label: "Common.female" },
  { value: "other", label: "Common.other" },
];

export const TRADE_TERMS = [
  "FOB",
  "EXW",
  "FCA",
  "FAS",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
  "DAT",
  "DDP",
  "DAP",
];

export const MAX_BUDGETS = [
  "<1000",
  "1000~5000",
  "5000~10000",
  "10000~50000",
  ">50000",
];

export const SHIPPING_METHODS = [
  "Sea freight",
  "Air freight",
  "Express",
  "Land freight",
];

export const PAYMENT_TERMS = [
  "T/T",
  "L/C",
  "D/P",
  "W/U",
  "Money Gram",
  "PayPal",
  "Other",
];

export const NUMBER_OF_EMPLOYEES = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  ">1000",
];

export const FREE_SUPPLIER_PLAN = "Free";

export const PLAN_NAMES = {
  free: "Free",
  trial: "Trial",
  pro: "Pro",
  advance: "Advance",
  starter: "Starter",
};

export const SUPPLIER_PLANS = [
  { label: PLAN_NAMES.free, planTextColor: "#9C2A10" },
  {
    label: PLAN_NAMES.pro,
    icon: PRO_CROWN_ICON,
    planTextColor: "#FDB022",
    chipClass: "yellow-label",
    label2: "Gold",
    planIcon: APP_ICONS.planPremium,
  },
  {
    label: PLAN_NAMES.advance,
    icon: ADVANCE_CROWN_ICON,
    planTextColor: "#293056",
    chipClass: "gray-label",
    label2: "Silver",
    planIcon: APP_ICONS.planSuperior,
  },
  {
    label: PLAN_NAMES.starter,
    icon: STARTED_CROWN_ICON,
    planTextColor: "#9C2A10",
    chipClass: "brown-label",
    label2: "Bronze",
    planIcon: APP_ICONS.planAdvance,
  },
  {
    label: PLAN_NAMES.trial,
    planTextColor: "#9C2A10",
    icon: STARTED_CROWN_ICON,
  },
];

export const VERIFICATION_LEVEL = {
  level1: "Level 1",
  level2: "Level 2",
  level3: "Level 3",
};

export const VERIFICATION_LEVELS = {
  level1: { title: "Level 1", daysToQuote: 14 },
  level2: { title: "Level 2", daysToQuote: 14 },
  level3: { title: "Level 3", daysToQuote: 7 },
};

export const REPORT_TYPE = {
  rfq: "RFQ",
  buyer: "Buyer",
  supplier: "Supplier",
  product: "Product",
};

export const ROLES = {
  buyer: "Buyer",
  supplier: "Seller",
  trader: "Both",
};

export const POSITIONS = [
  { label: "Founder/ Owner", value: "Founder/ Owner" },
  { label: "C-Level Manager", value: "C-Level Manager" },
  {
    label: "Sale/ Marketing/ Sourcing Manager",
    value: "Sale/ Marketing/ Sourcing Manager",
  },
  { label: "Staff", value: "Staff" },
  { label: "Freelancer/ Contractor", value: "Freelancer/ Contractor" },
  { label: "Other", value: "Other" },
];

export const INQUIRY_CONTEXT = [
  { label: "Find suppliers", value: "Find suppliers" },
  { label: "Find buyers", value: "Find buyers" },
  {
    label: "List my company",
    value: "List my company",
  },
  {
    label: "Get membership consultation",
    value: "Get membership consultation",
  },
  { label: "Freelancer/ Contractor", value: "Freelancer/ Contractor" },
  { label: "Report abuse", value: "Report abuse" },
];

export const PLANS: PricingPlanType[] = [
  {
    title: "Free",
    description: "free_plan_description",
    benefits: [
      {
        title: "free_benefit_1",
        description: "free_benefit_1_desc",
      },
      {
        title: "free_benefit_3",
        description: "free_benefit_3_desc",
      },
      {
        title: "free_benefit_4",
        description: "free_benefit_4_desc",
      },
      {
        title: "free_benefit_5",
        description: "free_benefit_5_desc",
      },
      {
        title: "free_benefit_6",
        description: "free_benefit_6_desc",
      },
      {
        title: "free_benefit_7",
        description: "free_benefit_7_desc",
      },
    ],
    actionText: "get_started",
  },
  {
    title: "Advance",
    description: "advance_plan_description",
    icon: APP_ICONS.planAdvance,
    benefits: [
      {
        title: "advance_benefit_1",
        description: "advance_benefit_1_desc",
      },
      {
        title: "advance_benefit_2",
        description: "advance_benefit_2_desc",
      },
      {
        title: "advance_benefit_3",
        description: "advance_benefit_3_desc",
      },
      {
        title: "advance_benefit_4",
        description: "advance_benefit_4_desc",
      },
      {
        title: "advance_benefit_5",
        description: "advance_benefit_5_desc",
      },
      {
        title: "advance_benefit_6",
        description: "advance_benefit_6_desc",
      },
      {
        title: "advance_benefit_7",
        description: "advance_benefit_7_desc",
      },
    ],
    actionText: "buy_now",
  },
  {
    title: "Superior",
    description: "superior_plan_description",
    icon: APP_ICONS.planSuperior,
    benefits: [
      {
        title: "superior_benefit_1",
        description: "superior_benefit_1_desc",
      },
      {
        title: "superior_benefit_2",
        description: "superior_benefit_2_desc",
      },
      {
        title: "superior_benefit_3",
        description: "superior_benefit_3_desc",
      },
      {
        title: "superior_benefit_4",
        description: "superior_benefit_4_desc",
      },
      {
        title: "superior_benefit_5",
        description: "superior_benefit_5_desc",
      },
      {
        title: "superior_benefit_6",
        description: "superior_benefit_6_desc",
      },
      {
        title: "superior_benefit_7",
        description: "superior_benefit_7_desc",
      },
    ],
    actionText: "buy_now",
  },
];

export const BUYING_FREQUENCIES = [
  { label: "one_time", value: "One time" },
  { label: "daily", value: "Daily" },
  { label: "weekly", value: "Weekly" },
  { label: "monthly", value: "Monthly", recommended: true },
  { label: "quarterly", value: "Quarterly" },
  { label: "semi_annually", value: "Semi Annually" },
  { label: "annually", value: "Annually" },
];

export const SALUTATIONS = [
  { value: "Madam" },
  { value: "Master" },
  { value: "Miss" },
  { value: "Mr" },
  { value: "Mrs" },
  { value: "Ms" },
];

export const COMPLAINT_TYPES = [
  { label: "scammer", value: "Scammer" },
  { label: "spam", value: "Spam" },
  {
    label: "unable_to_contact",
    value: "Unable to contact",
  },
  {
    label: "fake_false_information",
    value: "Fake/False information",
  },
  { label: "other", value: "Other" },
];

export { LISTING_SORTERS, COUNTRIES, GENDERS };
