import axios from "axios";
import { AI_AGENT_API_DOMAIN, API_DOMAIN } from "constants/schemas";
import { getCookie } from "helpers/cookie";
import { formatError } from "helpers/error";
import { showNotification } from "helpers/notification";

const createHttpClient = (baseURL?: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Cookie: `utm_source=${getCookie("utm_source")}`,
    },
    withCredentials: true,
  });

  // Add interceptors
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorMsg = formatError(error)?.message || "Something went wrong";
      showNotification(errorMsg);
      return Promise.reject(error);
    }
  );

  return instance;
};

const createHttpClientt = (baseURL?: string) => {
  const instance = axios.create({
    baseURL,
    // headers: {
    //   "Content-Type": "application/json",
    //   Accept: "application/json",
    // },
    // withCredentials: true,
  });

  // Add interceptors
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorMsg = formatError(error)?.message || "Something went wrong";
      showNotification(errorMsg);
      return Promise.reject(error);
    }
  );

  return instance;
};

const http = createHttpClient(API_DOMAIN);

export const agentHttp = createHttpClientt(AI_AGENT_API_DOMAIN);

export default http;
